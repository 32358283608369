import "./whyChooseUsSection.css";

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import Item from './Item'

import useEmblaCarousel from "embla-carousel-react";
// mui
import  Box  from "@mui/material/Box";
import  Typography  from "@mui/material/Typography";
import  IconButton  from "@mui/material/IconButton";
// icons

const WhyChooseUs = () => {

      
  // axios get req

  const [record,setRecord] =useState();
  
  useEffect(() => {
  
    const fetch = async () =>{
        const res = await axios.get(`/WhyChooseUs`);
        const data =res.data.records;
        setRecord(data);
        // console.log(data);   
    }
   fetch();
  },[]);
  
  if(record)
    return ( 
        <Box className="whyChooseUsSection"  >
                <Typography variant='h4' 
                  sx={{fontWeight: 500,color:`var(--primary)`,pl:2}}
                >Why Choose Us</Typography>
            <Box pt={3}></Box>
            <Box className="elements">
              {record && record.map((row) => <Item  {...row.fields} key={row.id} />)}
            </Box>
                    
                    
        </Box>
     );
     else return <div></div>
}
 
export default WhyChooseUs;