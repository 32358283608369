    import "./item.css";

    import Box  from "@mui/material/Box";
    import Typography from '@mui/material/Typography';
    import Button from '@mui/material/Button';

    import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

 

    const Item = ({description,sectionIcon}) => {
        var imgUrl;
        sectionIcon.map(row =>{
            imgUrl= row.url;
        })

        return ( 
            
            <Box className="itemWhyChooseUs" >
                <img src={imgUrl} alt="ab" className="keyPointsImg" />
                <Typography variant='body1'  className="featuresText" sx={{lineHeight:1,fontSize:{xs:14,sm:16},pb:0.5,fontWeight:'bold'}}>{description}</Typography>
            </Box>
            
         );
    }
     
    export default Item;