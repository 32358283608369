import { useState,useEffect } from "react";
import "./contentPage.css";
import axios from "axios";
import { useParams } from "react-router-dom";


const ContentPage = (props) => {
   const pageHead = props.head;
   const pageName = window.location.pathname ;

   const [subElements,setSubElements] = useState()  ;  
   const [heading,setHeading] = useState('') ;  
   const [tableName,setTableName] = useState('') ;  
   
   const params = new URLSearchParams(window.location.search)

   const Scroll = (scrollTo) => {
      const section = document.querySelector( `#${scrollTo}` );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      return;
    };
  
// for getting sub Elements
   const fetch = (url) =>{
      axios.get(`/${url}`).then(res=>{
         // console.log(res.data.records);
         setSubElements(res.data.records);
         setTimeout(() => {
            Scroll(params.get('scrollTo')) 
         }, 500);
      });        
   }  
   
   useEffect(() => {
      pageHead.map(row =>{
         if(`/${row.fields.value}` === pageName){
            setHeading(row.fields.name);
            setTableName(row.fields.connectedTable) 
            fetch(row.fields.connectedTable);
         }
        
      })
   }, [window.location.pathname ])

   
   

  
   
   if(subElements)
   return ( 
      <div className="contentPageDiv">
         
         <div className="contentPageLeft">
            <h1 className="mainHead">{heading}</h1>

            {subElements && subElements.map(row => {
               var imgUrl;
               row.fields.thumbnail && row.fields.thumbnail.map(row =>{
                     imgUrl= row.url;
               })
               return <div id={`${row.fields.value}`} key={row.id}>
                  <h4 className="cardHead" >{row.fields.name}</h4>
                  <div className="cardDiv">
                     <div className="imgDiv">
                        <img className="pageImg" src={imgUrl} />
                     </div>
                     <p className="content">{row.fields.description}</p>
                  </div>
                  
                  
               </div>

               })}
         </div>
         <div className="contentPageRight">
            
         {subElements && subElements.map(row => {
            return <p key={row.id} onClick={() =>Scroll(row.fields.value)}>{row.fields.name}</p>
         })}
         </div>
            

      </div>

    );
    else return <div></div>
   
}
 
export default ContentPage;