    import "./item.css";

    import Stack  from "@mui/material/Stack";
    import Box  from "@mui/material/Box";
    import Typography from '@mui/material/Typography';
    import Button from '@mui/material/Button';

    import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

    const Item = ({description,thumbnail,name,index}) => {

        var imgUrl;
        thumbnail.map(row =>{
            imgUrl= row.url;
        })

        return ( 
            
            <Box className="itemInfoBanners"   >
                <Box className={ index % 2 == 0 ? 'off':'itemBannerCardImgDiv'}>
                    <img src={imgUrl} alt='img' className="infoBannerImg" />
                </Box>
                <Box  pb={2} className="infoBannerTextDiv" >
                    <Typography variant="h3" fontWeight={600} pt={1}
                        sx={{color:'#013445'}} 
                        fontSize={{xs:30,md:45,lg:50}}
                        >{name}</Typography>
                    <p className="infoBannerText" >{description}</p>
                </Box>
                <Box className={ index % 2 == 0 ? 'itemBannerCardImgDiv':'off'}>
                    <img src={imgUrl} alt='img' className="infoBannerImg" />
                </Box>
            </Box>
            
         );
    }
     
    export default Item;