    import "./item.css";

    import { Link } from "react-router-dom";

    import Stack  from "@mui/material/Stack";
    import Box  from "@mui/material/Box";
    import Typography from '@mui/material/Typography';
    import Button from '@mui/material/Button';

    import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

    const Item = ({name,description,thumbnail,link,value}) => {

        var imgUrl;
        if(thumbnail){
            thumbnail.map((row,index) =>{
                if(index === 0 )
                imgUrl= row.thumbnails.large.url;
            })
        }
    

        return ( 
            
            <Stack className="itemTopFacility" alignItems="center">
                <Box className="facilityCardImgDiv">
                    <img src={imgUrl} alt='img' className="facilityCardImg" />
                </Box>
                <Box sx={{width:'90%'}} pb={2} pt={1} >
                    <Typography variant="h6" pt={1}>{name}</Typography>
                    <p className="facilityCardText" >{description}</p>
                    <Link to={`/facility?scrollTo=${value}`}>
                        <Button sx={{color:"var(--primary)",textTransform:'capitalize',mt:1}} >Read More</Button>
                    </Link>
                </Box>
                
            </Stack>
            
            
         );
    }
     
    export default Item;