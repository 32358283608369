    import "./item.css";

    import { useState } from "react";
    import axios from "axios";

    import Stack  from "@mui/material/Stack";
    import Typography from '@mui/material/Typography';
    import Button from '@mui/material/Button';

    import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Link } from "react-router-dom";

    const Item = ({description,pageUrl,name,value}) => {
        return ( 
            
            <Stack className="itemProcedure" alignContent={"center"}>
                <Typography variant='h6' sx={{color:'var(--primary)',fontSize:'18px'}} pl={2}>{name}</Typography>
                <p className="org" >{description}</p>
                <Link to={`/procedure?scrollTo=${value}`} >
                <Button endIcon={<TrendingFlatIcon />} sx={{color:"#0038FF",textTransform:'capitalize',width:135}}
                >Learn More</Button>
                </Link>

            </Stack>
            
         );
    }
     
    export default Item;