import "./doctors.css";

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import Item from './Item'

import useEmblaCarousel from "embla-carousel-react";
// mui
import  Box  from "@mui/material/Box";
import  Typography  from "@mui/material/Typography";
import  Button  from "@mui/material/Button";
import  IconButton  from "@mui/material/IconButton";
// icons
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const Doctors = () => {




      
  // axios get req

  const [record,setRecord] =useState();
  
  useEffect(() => {
  
    const fetch = async () =>{
        const res = await axios.get(`/Doctors`);
        const data =res.data.records;
        const sort=data.sort((a, b) => a.fields.timeUnix > b.fields.timeUnix ? 1 : -1)
        setRecord(sort);
        // console.log(data);   
    }
   fetch();
  },[]);
  
  if(record)
    return ( 
        <Box className="doctorsSection"  >
                <Typography variant='h4' 
                  sx={{fontWeight: 500,pl:2,color:`var(--primary)`}}
                >Our Doctors Team</Typography>
            <Box pt={3}></Box>
            <div className="embla">
                <div className="embla__viewport" >
                    <div className="embla__container">
                    {record && record.map((row) => (
                        <div className="embla__slide" key={row.id}>
                        <div className="embla__slide__inner">
                            <Item {...row.fields} />
                        </div>
                        </div>
                    ))}
                   
                    {!record && <div></div>}
                    </div>
                    <button className="embla__button embla__button--prev " type="button">
          
        </button>
        <button className="embla__button embla__button--next" type="button">
          
        </button>
                </div>
               
            </div>
            
        </Box>
     );
     else return <div></div>

}
 
export default Doctors;