import "./procedure.css";

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Skeleton } from "@mui/material";

import Item from './Item'

import useEmblaCarousel from "embla-carousel-react";
// mui
import  Box  from "@mui/material/Box";
import  Typography  from "@mui/material/Typography";

const Procedures = () => {


     
// axios get req

const [record,setRecord] =useState();

useEffect(() => {

  const fetch = async () =>{
      const res = await axios.get(`/Procedures`);
      const data =res.data.records;
      setRecord(data.sort((a, b) => a.fields.timeUnix > b.fields.timeUnix ? 1 : -1));
      // console.log(data);   
  }
 fetch();
},[]);


  if(record)  
    return ( 
       
        <Box className="proceduresSection"  >
                <Typography variant='h4' 
                  sx={{fontWeight: 500,pl:2,color:`var(--primary)`}}
                >Procedure</Typography>
            <Box pt={3}></Box>
            <div className="embla">
                <div className="embla__viewport" >
                    <div className="embla__container">
                    {record && record.map((row) => {
                      return(
                        <div className="embla__slide" key={row.id}>
                        <div className="embla__slide__inner">
                            <Item {...row.fields} />
                        </div>
                        </div>
                        )
                    })}
                    
                    {!record && <div></div>}
                    </div>
                    <button className="embla__button embla__button--prev" type="button">
          <svg
            className="embla__button__svg"
            viewBox="137.718 -1.001 366.563 643.999"
          >
            <path
              d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z"
            ></path>
          </svg>
        </button>
        <button className="embla__button embla__button--next" type="button">
          <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
            <path
              d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z"
            ></path>
          </svg>
        </button>
                </div>
                
            </div>
            
        </Box>
     );
     else return <Skeleton variant="rectangular" sx={{width:'100%',height:200,bgcolor: 'grey.400'}} mt={3} />
}
 
export default Procedures;