import "./infoBanners.css";

import { useState,useEffect } from "react";
import Item from './Item'
import axios from "axios";

// mui
import  Box  from "@mui/material/Box";
import  Stack  from "@mui/material/Stack";

const InfoBanners = () => {
      
  // axios get req

const [record,setRecord] =useState();

useEffect(() => {

  const fetch = async () =>{
      const res = await axios.get(`/Infobanners`);
      const data =res.data.records;
      setRecord(data);
      // console.log(data);   
  }
 fetch();
},[]);

  

    return ( 
        <Box className="infoBannersSection"  >
            <Box pt={3}></Box>
            <Stack className="infoBannersElements" alignItems="center" >
            {record && record.map((row,index) => (
            
                <Item key={row.id} {...row.fields} index={index}  />
            ))}
            </Stack>    
        </Box>
     );
}
 
export default InfoBanners;