import "./herobanner.css" ;

import { useEffect } from "react";
import Button from '@mui/material/Button';
import Herobanner from "images/Herobanner.png";
import { Skeleton } from "@mui/material";
// icons
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";


const Container = (props) =>{
  
  const records=props.records.sort((a, b) => a.fields.timeUnix > b.fields.timeUnix ? 1 : -1);
 
  return(
      <div className="heroBanner">
          <div className="embla">
            <div className="embla__viewport" >
                <div className="embla__container">
                {records && records.map((row) => {
                    var largeUrl ;
                    var smallUrl ;
                    row.fields.largeThumbnail.map(row =>{
                      largeUrl=row.url
                    })
                    row.fields.smallThumbnail.map(row =>{
                      smallUrl=row.thumbnails.large.url
                    })
                  return(
                    <div className="embla__slide" key={row.id}>
                    <div className="embla__slide__inner ">
                        <img src={largeUrl} alt="" className="heroLargeThumbnail " />
                        <img src={smallUrl} alt="" className="heroSmallThumbnail " />
                    </div>
                    </div>
                    )
                })}
                
                {!records && <div></div>}
                </div>
                  <button className="embla__button embla__button--prev embla_btn_style" type="button">
                    <NavigateBeforeIcon fontSize="large" />
                  </button>
                  <button className="embla__button embla__button--next embla_btn_style" type="button">
                   <NavigateNextIcon fontSize="large"/>
                  </button>
                </div>
                
            </div>
            <a href="./aboutus" className="heroBannerButton">Learn More</a>
      </div>
  )

}

const HeroBanner = () => {


  const [records,setRecords] =useState();

  useEffect(() => {

    const fetch = async () =>{
        const res = await axios.get(`/Herobanner`);
        const data =res.data.records;
        setRecords(data);
        // console.log(data);   
    }
   fetch();
  },[]);


  return (
    <div>
      {records && <Container records={records} /> } 
      {!records && <Skeleton variant="rectangular" animation="pulse" sx={{width:'100%',height:600,bgcolor: 'grey.400' }}/>} 
   </div>
  )

}
export default HeroBanner;