    import "./item.css";

    import Stack  from "@mui/material/Stack";
    import Typography from '@mui/material/Typography';
    import Button from '@mui/material/Button';
    import Box from '@mui/material/Box';

    import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Link } from "react-router-dom";

    const Item = ({qualification,specialization,name,thumbnail,description,link,value}) => {

        var imgUrl;
        thumbnail && thumbnail.map(row =>{
            imgUrl= row.url;
        })

        return ( 
            <Link to={`/doctors?scrollTo=${value}`}>
                <Stack className="itemDoctor" alignItems="center">
                    <Box className="doctorsCardImgDiv">
                        <img src={imgUrl} alt='img' className="doctorsCardImg" />
                    </Box>
                    <Box sx={{width:'90%'}}>
                        <Typography variant="h6"  className="doctorName" pt={1}>{name}</Typography>
                        <p className="facilityCardText" >{qualification} </p>
                    </Box>
                    
                </Stack>
            </Link>
            
         );
    }
     
    export default Item;