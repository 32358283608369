import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from 'pages/Home';
import Navbar from 'components/Navbar/Navbar';
import ContentPage from 'components/ContentPage/ContentPage';
import { useState,useEffect } from 'react';

// mui Theme
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import {  ThemeProvider } from '@mui/material/styles';
import Footer from 'components/Footer/Footer';
import axios from 'axios';
import { Box } from '@mui/material';
import PrivacyPolicy from 'pages/privacypolicy';



// Create a theme instance.
const theme = createTheme({
  typography: {
      "fontFamily": `"DM Sans",sans-serif`,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
      },
  button:{
      "textTransform":"capitalize",
      "Elevation":0
  },
    palette: {
      primary: {
        main: '#556cd6',
      },
      secondary: {
        main: '#19857b',
      },
      error: {
        main: red.A400,
      },
    },
  });
  

function App() {

  // Axois Defaults

  axios.defaults.baseURL = 'https://api.airtable.com/v0/appb9UG9gbD9QyOx0';
  axios.defaults.headers.common['Authorization'] = "Bearer pat1TzP1SZH2n4W3w.f0a4acd3b61521688cbfeaf19ccb230416418b4173a279ea8d7b4c528f3ea4ba";
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  const [navHead, setNavHead] = useState();
  const [navElements,SetNavElements]=useState();

  useEffect(() => {
    const fetch =async () =>{
      axios.get("/FooterCategories").then(res=>{
        // console.log(res.data.records);
        setNavHead(res.data.records)
      });
      
      axios.get("/FooterSubelements").then(res=>{
        // console.log(res);
        SetNavElements(res.data.records)
      });
    }
    fetch();
  }, [])

  var datalayer;
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments)}
    gtag('js', new Date());

    gtag('config', 'G-F96YX65MJ2');
  
  return (
    <div className="App">
      
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            {navHead && <Navbar data={navHead}/>}
            <Box height={{xs:80,md:0}}/>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path=":subPage" element={navHead && <ContentPage elements={navElements} head={navHead}  />} />
            </Routes>
            {navHead && navElements && <Footer head={navHead} elements={navElements}/>}
          </ThemeProvider >
        </BrowserRouter>

        {/* google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F96YX65MJ2"></script>
       

    </div>
  );
}

export default App;



