const PrivacyPolicy = () => {
  const style ={
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    display:"block",
    marginTop:10

  }
    return ( 
        <div style={style} >
            <h2>Privacy Policy</h2>
            <h4>OFFICIAL WEBSITE LEGAL NOTICE</h4>
            <p>© 2022 Copyright SANKANUR HOSPITAL AND RESEARCH CENTER GADAG. 2020. All rights reserved. Reproduction, adaptation, or translation without permission is prohibited except as allowed under the International copyright laws. All the text, graphics, design, content, and other works are the copyrighted works of SANKANUR HOSPITAL AND RESEARCH CENTER GADAG.</p>
            <h3>TERMS AND CONDITIONS OF USE</h3>
            <p>Welcome to the official website of SANKANUR HOSPITAL AND RESEARCH CENTER GADAG https://www.sankanurhospital.com/  Use of this site is governed by the Terms and Conditions set forth. PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEBSITE. The information and materials provided by SANKANUR HOSPITAL AND RESEARCH CENTER GADAG may be used for informational purposes only. By using, accessing or downloading materials from this website you agree to follow the terms and provisions as outlined in this legal notice, which apply to all visits to the SANKANUR HOSPITAL AND RESEARCH CENTER GADAG website, both now and in the future. SANKANUR HOSPITAL AND RESEARCH CENTER GADAG may at any time revise and update the Terms and Conditions. You are encouraged to periodically visit this page to review the most current Terms and Conditions to which you are bound. If you do not agree to these Terms and Condition of Use, please do not use this website.</p>
            <h3>RESTRICTIONS</h3>
            <p>You may view, download and copy information and materials available on this website solely for your personal, non-commercial use. You may also use such material within your organization in connection with the support of SANKANUR HOSPITAL AND RESEARCH CENTER GADAG's products. As a condition of use, you agree not to modify or revise any of the material in any manner, and to retain all copyright and other proprietary notices as contained in the original materials on any copies of the materials. No other use of the materials or information is authorized. Any violation of the foregoing may result in civil and/or criminal liabilities.
            </p>
            <h3>OWNERSHIP OF INFORMATION AND MATERIALS</h3>
            <p>The information and any materials (including white papers, press releases, data sheets, product descriptions, and FAQs) available on or from this website are the copyrighted works of SANKANUR HOSPITAL AND RESEARCH CENTER GADAG, and any unauthorized use of that information or materials may violate copyright, trademark and other laws. Any rights not expressly granted herei n are reserved.</p>
            <h3>TRADEMARK INFORMATION</h3>
            <p>SANKANUR HOSPITAL AND RESEARCH CENTER GADAG's trademarks may be used only with written permission from SANKANUR HOSPITAL AND RESEARCH CENTER GADAG. All other trademarks, brands, and names are the property of their respective owners. Except as expressly specified in these terms and legal restrictions, nothing contained herein shall be construed as conferring by implication, estoppel or otherwise any license or right under any patent, trademark, copyright or any proprietary rights of SANKANUR HOSPITAL AND RESEARCH CENTER GADAG or any third party.</p>
            <h3>LINKS TO OTHER WEBSITES</h3>
            <p>As a convenience and to make the SANKANUR HOSPITAL AND RESEARCH CENTER GADAG website truly service oriented we have included links to complementary sites on the Internet. These sites are owned and operated by third parties. SANKANUR HOSPITAL AND RESEARCH CENTER GADAG makes no representation and is not responsible for the availability of, or content located on or through, these third party sites. A third party link from the SANKANUR HOSPITAL AND RESEARCH CENTER GADAG The website is not an indication that SANKANUR HOSPITAL AND RESEARCH CENTER GADAG endorses the third party or its site, or has any affiliation with or between SANKANUR HOSPITAL AND RESEARCH CENTER GADAG and the third party hosting site.</p>
            <h3>FEEDBACK</h3>
            <p>All comments, feedback, information or materials submitted to SANKANUR HOSPITAL AND RESEARCH CENTER GADAG through or in association with this website shall be considered non-confidential and SANKANUR HOSPITAL AND RESEARCH CENTER GADAG's property. By submitting such comments, information, feedback, or materials to SANKANUR HOSPITAL AND RESEARCH CENTER GADAG, you agree to a no-charge assignment to SANKANUR HOSPITAL AND RESEARCH CENTER GADAG of worldwide rights to use, copy, modify, display and distribute the submissions. SANKANUR HOSPITAL AND RESEARCH CENTER GADAG may use such comments, information or materials in any way it chooses on an unrestricted basis.</p>
            <h3>DISCLAIMER</h3>
            <p>The SANKANUR HOSPITAL AND RESEARCH CENTER GADAG The Internet team strives to provide you with useful, accurate, and timely information on this website. Accordingly, SANKANUR HOSPITAL AND RESEARCH CENTER GADAG has attempted to provide accurate information and materials on this website but assumes no responsibility for the accuracy and completeness of that information or materials. SANKANUR HOSPITAL AND RESEARCH CENTER GADAG may change the content of any information or materials available at this website, or to the products described in them, at any time without notice. However, SANKANUR HOSPITAL AND RESEARCH CENTER GADAG makes no commitment to update the information or materials on this website which, as a result, may be out of date. Information and opinions expressed in bulletin boards or other forums are not necessarily those of SANKANUR HOSPITAL AND RESEARCH CENTER GADAG. Neither SANKANUR HOSPITAL AND RESEARCH CENTER GADAG, nor its officers, directors, employees, agents, distributors, or affiliates are responsible or liable for any loss damage (including, but not limited to, actual, consequential, or punitive), liability, claim, or other injury or cause related to or resulting from any information posted on SANKANUR HOSPITAL AND RESEARCH CENTER GADAG's website. SANKANUR HOSPITAL AND RESEARCH CENTER GADAG reserves the right to revise these terms and/or legal restrictions at any time. You are responsible for reviewing this page from time to time to ensure compliance with the then-current terms and legal restrictions because they will be binding on you. Certain provisions of these terms and legal restrictions may be superseded by expressly designated legal notices or terms located on particular pages of this website.<br />
            ALL INFORMATION AND MATERIALS AVAILABLE AT THIS WEBSITE ARE PROVIDED "AS IS" WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND SANKANUR HOSPITAL AND RESEARCH CENTER GADAG DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY OR ARISING FROM A COURSE OF DEALING, USAGE OR TRADE PRACTICE. IN NO EVENT SHALL SANKANUR HOSPITAL AND RESEARCH CENTER GADAG BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INDIRECT, SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR THOSE RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF THE USE, INABILITY TO USE, OR THE RESULTS OF USE OF THIS WEBSITE, ANY WEBSITES LINKED TO THIS WEBSITE, OR THE MATERIALS OR INFORMATION CONTAINED AT ANY OR ALL SUCH WEBSITES, WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF YOUR USE OF THE MATERIALS OR INFORMATION ON THIS WEBSITE RESULTS IN THE NEED FOR SERVICING, REPAIR OR CORRECTION OF EQUIPMENT OR DATA, YOU ASSUME ALL COSTS THEREOF.
            </p>
            <h3>TERMINATION OF USE</h3>
            <p>SANKANUR HOSPITAL AND RESEARCH CENTER GADAG may, in its sole discretion, terminate or suspend your access to all or part of the SANKANUR HOSPITAL AND RESEARCH CENTER GADAG website, including, but not limited to any bulletin boards on its site, for any reason, including without limitation, breach of this agreement. In the event this agreement is terminated, the restrictions regarding materials appearing on the site and the representations and warranties, indemnities, and limitation of liabilities set forth in this agreement shall survive any such termination.
            GOVERNING LAW; JURISDICTION AND VENUE
            This Agreement shall be governed by and construed in accordance with the laws of the Karnataka of INDIA. The Karnataka courts shall have exclusive jurisdiction and venue over any dispute arising out of or relating to this Agreement, and each party hereby consents to the jurisdiction and venue of such courts.
            </p>
            <h3>GENERAL PROVISIONS</h3>
            <p>If any provision of this agreement is deemed void, unlawful or otherwise unenforceable for any reason, that provision shall be severed from this agreement and the remaining provisions of this agreement shall remain in force. This contains the entire agreement between you and SANKANUR HOSPITAL AND RESEARCH CENTER GADAG concerning your use of the site, and the agreement shall not be modified, except in writing, signed by both parties. If you have questions regarding SANKANUR HOSPITAL AND RESEARCH CENTER GADAG's Terms and Conditions, please email: shrcgadag@gmail.com or info@sankanurhospital.com or read the Privacy Policy.</p>
        </div>
     );
}
 
export default PrivacyPolicy;
