import "./topfacility.css";

import axios from "axios";
import Item from './Item'
import { useState,useEffect } from "react";


import useEmblaCarousel from "embla-carousel-react";
// mui
import  Box  from "@mui/material/Box";
import  Stack  from "@mui/material/Stack";
import  Typography  from "@mui/material/Typography";
import  Button  from "@mui/material/Button";
import  IconButton  from "@mui/material/IconButton";
// icons
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const TopFacility = () => {


  // axios get req

  const [allrecord,setAllRecord] =useState();
  const [slice,setSlice] =useState();
  const [display,setDisplay] =useState("See All");
  
  useEffect(() => {
  
    const fetch = async () =>{
        const res = await axios.get(`/Facilities`);
        const data =res.data.records;
        setAllRecord(data);
        setSlice(data.slice(0,6));
        // console.log(data);   
    }
   fetch();
  },[]);
  
  const increament =() =>{
    if(display === "See All"){
      setSlice(allrecord);
      setDisplay("Collapse")
    }
    else {
      setSlice(allrecord.slice(0,6));
      setDisplay("See All")

    }
  }
  if(allrecord)
    return ( 
        <Box className="topFacilitySection"  >
            <Typography variant='h4' 
              sx={{fontWeight: 500,pl:2,color:`var(--primary)`}}
            >Top Facility</Typography>
            <Box pt={3}></Box>
            <Box className="topFacilityElements">
            {slice && slice.map((row) => (

              <Item key={row.id} {...row.fields} />
                
            ))}
            </Box>
            <Button className="topFacilityShowMore"
              onClick={increament}
              size="large" fullWidth 
              sx={{color:'var(--primary)',textTransform:'capitalize' ,fontSize:18}}
            >{display}</Button>
            
        </Box>
     );
     else return <div></div>
}
 
export default TopFacility;